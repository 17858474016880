import React from 'react';
import TextToggle from '../components/text-toggle/text-toggle';
import WoodHome from '../images/wood-home-ready.png';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../components/layout";
import SEO from "../components/seo";
import TopBunerProduct from '../components/top-buner/top-baner-product';
import OfficeAtMap from '../components/office-on-map/office-on-map';
import ImagesView from '../components/images-view/images-view';
import Advantages from '../components/advantages/advantages';
import FormQuickOrder from '../components/form-quick-oreder/form-quick-order';


const PokraskaDomaIzSruba = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Покраска сруба из бруса', url:'#', status:true}]
  return (
    <div>
        <SEO title="Покраска дома из бруса в Калининградской области"
            description="Опытные бригады в срок выполнят работы по покраске восстановлению дома из бруса в Калининграде.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Покраска дома из бруса в Калининград&shy;ской области'} 
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={''}
                      breadCrumbs={breadCramb}>
            </TopBunerProduct>
            <Container fluid>
        <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify">
                <h1 className="text-center text-md-left">Покраска дома из бруса</h1>
                <p>
                Покрасить стены сруба из бруса снаружи значит защитить дом от разрушительного воздействия влаги, 
                проникновения биологических вредителей, ультрафиолетового излучения и прочей агрессии окружающей среды.  
                Кроме того, новая отделка должна улучшить эстетические качества постройки. 
                Для этих целей может использоваться лак или различные виды красок необходимого цвета и оттенка.
                </p>
                <Advantages />
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={WoodHome} className="w-100" style={{borderRadius:"0px 0px 150px 14px"}}  alt="" title="" />
              </Col>
            </Row>
        </Container>
        <Container fluid className="price-table">
          <Container className="mt-2 p-1 p-md-3">
            <Row>
              <h2>Цены на работы по наружной окаске сруба</h2>
                <Col md="12" className="mt-2">
                  <table className="table table-striped p-table">
                  <thead>
                    <tr>
                      <th scope="col">№</th> 
                      <th scope="col">Услуга</th>
                      <th scope="col">Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Чистка водой под давлением с применением химии</td>
                      <td>от <span className="price-list">50</span> рублей за м<sup><small>2</small> </sup></td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Механическая чистка с применением инструмента, включая торцы</td>
                      <td>от <span className="price-list">350</span> рублей за м<sup><small>2</small> </sup></td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Покраска в один слой краской с грунтовкой, конопаткой (джутом)</td>
                      <td>от <span className="price-list">100</span> рублей за м<sup><small>2</small> </sup></td>
                    </tr>
                  </tbody>
                </table>
                </Col>
            </Row>
          </Container>
         </Container>
         <Container>
           <Row>
             <Col md="12">
             <h2>Покраска продлевает срок эксплуатации деревянного дома!</h2>
             <h3>Негативные факторы, влияющие разрушительно на наружную поверхность стен сруба</h3>
             <p>
             Красить дерево рекомендуется по причине необходимости защиты поверхности от множества 
             пагубных природных факторов, которому фасад дома постоянно подвергается.</p>
             <ul>
               <li>
               <span className="price-list">Влажность</span> <br />
               Под действием дождя и снега древесина, набухая, трескается, 
               теряет прочность, термоизоляционные свойства. 
               В трещины проникают жуки древоточцы, скапливается грязь, образуется плесень. 
               Различные гидрофобные краски, масла и пропитки защищают поверхность от этих неприятностей.
               </li>
               <li>
               <span className="price-list">Солнечный свет</span> <br />
               Под его воздействием поверхностный слой дерева изменяет цвет, а затем начинает разрушаться. 
               </li>
               <li>
               <span className="price-list">Биогенные воздействия</span> <br />
               Различные насекомые, древоточцы, грибок и плесень могут быстро уничтожить деревянные стены. 
               В этом случае используется пропитка и краска, обладающая инсектицидными и антибактериальными свойствами.
               </li>
             </ul>             
             </Col>
             <Col md="12">
              <h3>Преимущества своевременной покраски дома из бруса:</h3>
              <ul>
              <li>
               <span className="price-list">Улучшение эстетики</span> <br />
               Дом, окрашенный в правильно подобранный цвет, 
               приятно радует глаз и гармонирует с прилегающей территорией и размещенными на ней объектами.
               </li>
               <li>
               <span className="price-list">Защита</span> <br />
               Защита от агрессивных атмосферных явлений. 
               В первую очередь это связано с влагой: дождем и снегом, 
               но также относится к сильному ветру, граду и прочим явлениям.
               </li>
               <li>
               <span className="price-list">Биологическая защита</span> <br />
               Древесина – это отличная среда для размножения плесневого грибка и других микроорганизмов, 
               кроме того, в ней могут завестись жучки, а еще ею питаются грызуны. 
               Качественная краска защитит материал от этих проблем.
               </li>
               <li>
               <span className="price-list">Защита от ультрафиолета</span> <br />
               Солнечный свет также оказывает негативное влияние на древесину: 
               приводит к пересыханию и выцветанию ее естественного окраса.
               </li>
               <li>
               <span className="price-list">Возможность сохранения аутентичной окраски</span> <br />
               Лессирующие лакокрасочные составы покрывают брус прозрачным покрытием, 
               позволяя в полной мере наслаждаться его природной красотой.
               </li>
               <li>
               <span className="price-list">Устранение мелких дефектов</span> <br />
               Дерево – это неоднородный материал, 
               поэтому зачастую на его поверхности присутствуют пигментационные пятна или другие дефекты.
               </li>
              </ul>
             </Col>
           </Row>
         </Container>
        <FormQuickOrder />
        <Container className="mt-5">
          <Row>
            <Col>
            <h3>Виды красок для дома из бруса</h3>
            <p>Основные характеристики лакокрасочных покрытий – это тип, цвет, 
              длительность срока службы. Срок эксплуатации краски определяется устойчивостью 
              к воздействию влаги, солнечных лучей, перепадам температур.
            </p>
            <ul>
              <li>
                <span className="price-list">Латексная краска</span> <br />
                Создает устойчивое эластичное покрытие, которое защищает от плесени и грибка, 
                обладает высоким уровнем паропроницания и экологически безопасен.
              </li>
              <li>
                <span className="price-list">Акриловая краска</span> <br />
                Отличается высокой прочностью и отлично защищает поверхность стен от внешних воздействий. 
                Стены покрашенные акриловой краской можно мыть абразивными средствами. 
                Производители предлагают обширную палитру цветов. 
              </li>
              <li>
                <span className="price-list">Алкидная</span> <br />
                Лакокрасочное средство на основе алкидных смол. 
                Алкидный состав помогает защитить дерево от влаги, морозов и других внешних факторов.
              </li>
              <li>
                <span className="price-list">Масляная</span> <br />
                Защитные свойства у масляных красок высоки, цветовая палитра многообразна. 
                Но они источают резкий запах, просушка длиться долго, также не отличаются 
                устойчивостью к ультрафиолету, служит покрытие не долго.
              </li>
            </ul>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <h2>Профессиональные услуги по окраске сруба в Калининграде.</h2>
              <p>
              Компания «ГрутВуд» выполняет все виды работ по покраске и восстановлению срубов. 
              Мы используем только проверенные материалы, прошедшие необходимую сертификацию. 
              Все работы проводятся с обязательным соблюдением технологии и температурного режима. 
              </p>
              <p>
              Оказывая услуги по покраске домов на протяжении семи лет мы накопили обширный опыт работы с древесиной, 
              что позволяет нам качественно и в срок выполнять любые объемы работ. 
              Наши специалисты подберут варианты покраски индивидуально для Вашего дома  учитывая дизайн, 
              отдельные характеристики, проблемные места. 
              Более индивидуальные вопросы вы можете уточнить у наших специалистов.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Этапы работ по окраске сруба из бруса</h3>
              <p>Наши специалисты выполняют покрасочные работы в соответствии с технологией и 
                требованиями производителей по использованию окрашивающих материалов. 
              </p>
              <ul>
              <li>
                <span className="price-list">Подготовка к покраске</span> <br />
                Поверхность бруса очищается  от грязи или остатков старой краски.<br />
                Удаляются  значительные неровности, шероховатости, потеки смолы или слои старой краски. <br />
                Крупные щели очищаются от вредителей, обрабатываются и  замазываются специальным герметиком.<br />
                Поверхность стен шлифуется специальным оборудованием в несколько этапов. <br />
                Завершающим этапом подготовки является полировка поверхности стен.<br />

              </li>
              <li>
                <span className="price-list">Покраска стен </span> <br />
                Окрашивания внешних стен дома производится только в сухую погоду.
                Подготавливается инструмент и используемые материалы. 
                Выбираются методы окраски в зависимости от объема работ, 
                состояния поверхности и доступности. 
                Краска наносится в несколько слоев, 
                каждый последующий слой после высыхания предыдущего.
              </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <ImagesView />
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default PokraskaDomaIzSruba;